<template>
<el-scrollbar class="main-wrapper">
  <el-breadcrumb separator=">" class="genera-breadcrumb" style="margin:0 20px">
    <el-breadcrumb-item>实操题</el-breadcrumb-item>
    <el-breadcrumb-item>添加试卷</el-breadcrumb-item>
  </el-breadcrumb>
  <el-form ref="paperForm" :model="paperForm" :rules="paperFormRules" label-width="180px" style="margin-left: 30px;margin-top: 24px">
    <el-form-item label="试卷名称" prop="name">
      <el-input v-model="paperForm.name" style="width: 500px;" ref="cursorInput" placeholder="请输入试卷名称"></el-input>
    </el-form-item>
    <el-form-item label="考试等级" prop="exam_grade">
      <el-select style="width: 500px;" v-model="paperForm.exam_grade" placeholder="选择考试等级" @change="selectExamGrade">
        <el-option
            v-for="item in gradeClassList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <span style="margin-left: 20px; color: #606266">考核时间:分</span>
    </el-form-item>
    <template v-if="paperForm.exam_grade === 1">
      <el-form-item label="产品及服务信息管理" prop="goods_info_collect">
        <el-select style="width: 375px;" @change="selectChange($event,1)" v-model="paperForm.goods_info_collect" filterable clearable @clear="clearScore(paperForm.goods_info_collect, 1)" placeholder="请选择试题">
          <el-option
                  v-for="item in collectList"
                  :key="item.id"
                  :label="item.op_name"
                  :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="collectScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="goods_info_collect_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="线上店铺设计与装修" prop="shop_decoration">
        <el-select style="width: 375px;" @change="selectChange($event,2)" v-model="paperForm.shop_decoration" filterable clearable @clear="clearScore(paperForm.shop_decoration, 2)" placeholder="请选择试题">
          <el-option
                  v-for="item in decorationList"
                  :key="item.id"
                  :label="item.op_name"
                  :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="decorationScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="shop_decoration_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="营销推广" prop="network_promotion">
        <el-select style="width: 375px;" @change="selectChange($event,4)" v-model="paperForm.network_promotion" filterable clearable @clear="clearScore(paperForm.network_promotion, 4)" placeholder="请选择试题">
          <el-option
                  v-for="item in promotionList"
                  :key="item.id"
                  :label="item.op_name"
                  :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="promotionScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="network_promotion_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="业务处理" prop="shop_management">
        <el-select style="width: 375px;" @change="selectChange($event,3)" v-model="paperForm.shop_management" filterable clearable @clear="clearScore(paperForm.shop_management, 3)" placeholder="请选择试题">
          <el-option
                  v-for="item in managementList"
                  :key="item.id"
                  :label="item.op_name"
                  :value="item.id">
          </el-option>
        </el-select>
        <el-input-number  :min="1" :max="100" :precision="0" :controls="false" v-model="managementScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="shop_management_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="客户服务" prop="store_customer_service">
        <el-select style="width: 375px;" @change="selectChange($event,5)" v-model="paperForm.store_customer_service" filterable clearable @clear="clearScore(paperForm.store_customer_service, 5)" placeholder="请选择试题">
          <el-option
                  v-for="item in serviceList"
                  :key="item.id"
                  :label="item.op_name"
                  :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="serviceScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="store_customer_service_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="商务数据分析" prop="data_capture_clean">
        <el-select style="width: 375px;" @change="selectChange($event,6)" v-model="paperForm.data_capture_clean" filterable clearable @clear="clearScore(paperForm.data_capture_clean, 6)" placeholder="请选择试题">
          <el-option
                  v-for="item in cleanList"
                  :key="item.id"
                  :label="item.op_name"
                  :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="cleanScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="data_capture_clean_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
    </template>
    <template v-if="paperForm.exam_grade === 24">
      <el-form-item label="产品及服务信息管理" prop="product_service_management">
        <el-select style="width: 375px;" @change="selectChange($event,12)" v-model="paperForm.product_service_management" filterable clearable @clear="clearScore(paperForm.product_service_management, 12)" placeholder="请选择试题">
          <el-option
                  v-for="item in productServiceList"
                  :key="item.id"
                  :label="item.op_name"
                  :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="productServiceScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="product_service_management_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="线上店铺设计与装修" prop="store_visual_design">
        <el-select style="width: 375px;" @change="selectChange($event,7)" v-model="paperForm.store_visual_design" filterable clearable @clear="clearScore(paperForm.store_visual_design, 7)" placeholder="请选择试题">
          <el-option
                  v-for="item in storeVisualDesignList"
                  :key="item.id"
                  :label="item.op_name"
                  :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="storeVisualDesignScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="store_visual_design_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="营销推广" prop="network_marketing">
        <el-select style="width: 375px;" @change="selectChange($event,8)" v-model="paperForm.network_marketing" filterable clearable @clear="clearScore(paperForm.network_marketing, 8)" placeholder="请选择试题">
          <el-option
                  v-for="item in networkMarketingList"
                  :key="item.id"
                  :label="item.op_name"
                  :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="networkMarketingScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="network_marketing_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="业务处理" prop="online_transaction_management">
        <el-select style="width: 375px;" @change="selectChange($event,9)" v-model="paperForm.online_transaction_management" filterable clearable @clear="clearScore(paperForm.online_transaction_management, 9)" placeholder="请选择试题">
          <el-option
                  v-for="item in onlineTransactionManagementList"
                  :key="item.id"
                  :label="item.op_name"
                  :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="onlineTransactionManagementScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="online_transaction_management_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="客户服务" prop="store_customer_service_level_three">
        <el-select style="width: 375px;" @change="selectChange($event,10)" v-model="paperForm.store_customer_service_level_three" filterable clearable @clear="clearScore(paperForm.store_customer_service_level_three, 10)" placeholder="请选择试题">
          <el-option
                  v-for="item in storeCustomerServiceLevelThreeList"
                  :key="item.id"
                  :label="item.op_name"
                  :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="storeCustomerServiceLevelThreeScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="store_customer_service_level_three_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="商务数据分析" prop="data_analysis_application">
        <el-select style="width: 375px;" @change="selectChange($event,11)" v-model="paperForm.data_analysis_application" filterable clearable @clear="clearScore(paperForm.data_analysis_application, 11)" placeholder="请选择试题">
          <el-option
                  v-for="item in dataAnalysisApplicationList"
                  :key="item.id"
                  :label="item.op_name"
                  :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="dataAnalysisApplicationScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="data_analysis_application_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      
    </template>
    <template v-if="paperForm.exam_grade === 74">
      <el-form-item label="产品及服务信息管理" prop="product_service_management">
        <el-select style="width: 375px;" @change="selectChange($event,12)" v-model="paperForm.product_service_management" filterable clearable @clear="clearScore(paperForm.product_service_management, 12)" placeholder="请选择试题">
          <el-option
              v-for="item in productServiceList"
              :key="item.id"
              :label="item.op_name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="productServiceScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="product_service_management_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="线上店铺设计与装修" prop="store_visual_design">
        <el-select style="width: 375px;" @change="selectChange($event,7)" v-model="paperForm.store_visual_design" filterable clearable @clear="clearScore(paperForm.store_visual_design, 7)" placeholder="请选择试题">
          <el-option
              v-for="item in storeVisualDesignList"
              :key="item.id"
              :label="item.op_name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="storeVisualDesignScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="store_visual_design_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="营销推广" prop="network_marketing">
        <el-select style="width: 375px;" @change="selectChange($event,8)" v-model="paperForm.network_marketing" filterable clearable @clear="clearScore(paperForm.network_marketing, 8)" placeholder="请选择试题">
          <el-option
                  v-for="item in networkMarketingList"
                  :key="item.id"
                  :label="item.op_name"
                  :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="networkMarketingScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="network_marketing_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="业务处理" prop="shop_management">
        <el-select style="width: 375px;" @change="selectChange($event,3)" v-model="paperForm.shop_management" filterable clearable @clear="clearScore(paperForm.shop_management, 3)" placeholder="请选择试题">
          <el-option
              v-for="item in managementList"
              :key="item.id"
              :label="item.op_name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input-number  :min="1" :max="100" :precision="0" :controls="false" v-model="managementScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="shop_management_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>

      <el-form-item label="客户服务" prop="store_customer_service">
        <el-select style="width: 375px;" @change="selectChange($event,5)" v-model="paperForm.store_customer_service" filterable clearable @clear="clearScore(paperForm.store_customer_service, 5)" placeholder="请选择试题">
          <el-option
              v-for="item in serviceList"
              :key="item.id"
              :label="item.op_name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="serviceScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="store_customer_service_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>

      <el-form-item label="商务数据分析" prop="data_analysis_application">
        <el-select style="width: 375px;" @change="selectChange($event,11)" v-model="paperForm.data_analysis_application" filterable clearable @clear="clearScore(paperForm.data_analysis_application, 11)" placeholder="请选择试题">
          <el-option
              v-for="item in dataAnalysisApplicationList"
              :key="item.id"
              :label="item.op_name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="dataAnalysisApplicationScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="data_analysis_application_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
    </template>
    <template v-if="paperForm.exam_grade === 51">
      <el-form-item label="产品及服务信息管理" prop="goods_info_collect">
        <el-select style="width: 375px;" @change="selectChange($event,1)" v-model="paperForm.goods_info_collect" filterable clearable @clear="clearScore(paperForm.goods_info_collect, 1)" placeholder="请选择试题">
          <el-option
              v-for="item in collectList"
              :key="item.id"
              :label="item.op_name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="collectScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="goods_info_collect_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="线上店铺设计与装修" prop="store_visual_design">
        <el-select style="width: 375px;" @change="selectChange($event,7)" v-model="paperForm.store_visual_design" filterable clearable @clear="clearScore(paperForm.store_visual_design, 7)" placeholder="请选择试题">
          <el-option
              v-for="item in storeVisualDesignList"
              :key="item.id"
              :label="item.op_name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="storeVisualDesignScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="store_visual_design_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="营销推广" prop="network_promotion">
        <el-select style="width: 375px;" @change="selectChange($event,4)" v-model="paperForm.network_promotion" filterable clearable @clear="clearScore(paperForm.network_promotion, 4)" placeholder="请选择试题">
          <el-option
              v-for="item in promotionList"
              :key="item.id"
              :label="item.op_name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="promotionScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="network_promotion_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
      <el-form-item label="业务处理" prop="shop_management">
        <el-select style="width: 375px;" @change="selectChange($event,3)" v-model="paperForm.shop_management" filterable clearable @clear="clearScore(paperForm.shop_management, 3)" placeholder="请选择试题">
          <el-option
              v-for="item in managementList"
              :key="item.id"
              :label="item.op_name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input-number  :min="1" :max="100" :precision="0" :controls="false" v-model="managementScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="shop_management_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>

      <el-form-item label="客户服务" prop="store_customer_service">
        <el-select style="width: 375px;" @change="selectChange($event,5)" v-model="paperForm.store_customer_service" filterable clearable @clear="clearScore(paperForm.store_customer_service, 5)" placeholder="请选择试题">
          <el-option
              v-for="item in serviceList"
              :key="item.id"
              :label="item.op_name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="serviceScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="store_customer_service_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>

      <el-form-item label="商务数据分析" prop="data_capture_clean">
        <el-select style="width: 375px;" @change="selectChange($event,6)" v-model="paperForm.data_capture_clean" filterable clearable @clear="clearScore(paperForm.data_capture_clean, 6)" placeholder="请选择试题">
          <el-option
              v-for="item in cleanList"
              :key="item.id"
              :label="item.op_name"
              :value="item.id">
          </el-option>
        </el-select>
        <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="cleanScore" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>%
        <el-input-number :min="1" :max="99999" :precision="0" :controls="false" v-model="data_capture_clean_time" style="width: 80px;margin-left: 20px;margin-right: 10px"></el-input-number>
      </el-form-item>
    </template>
  </el-form>
  <div class="dialog-footer">
    <el-button @click="toBack">取消</el-button>
    <el-button style="margin-left: 30px" type="primary" @click="saveForm('paperForm')">保存</el-button>
  </div>
</el-scrollbar>
</template>

<script>
import {operationProblemExamModuleList, operationProblemEpOpList, operationProblemSaveOpExamPapers, operationProblemOpEpDetail} from "@/utils/apis";

export default {
  name: "AddPaperManagement",
  data(){
    return {
      paperForm:{
        id:'',
        name:'',
        exam_grade:'',
        goods_info_collect:'',
        shop_decoration:'',
        shop_management:'',
        network_promotion:'',
        store_customer_service:'',
        data_capture_clean:'',
        store_visual_design: '',
        network_marketing: '',
        online_transaction_management: '',
        store_customer_service_level_three: '',
        data_analysis_application: '',
        product_service_management: '',
      },
      paperFormRules:{
        name:[
          { required: true, message: '请输入试卷名称', trigger: 'blur' },
          { min: 2, max: 18, message: '长度在 2 到 18 个字符', trigger: 'blur' }
        ],
        exam_grade:[
          { required: true, message: '请选择考试等级', trigger: 'change' }
        ]
      },
      gradeClassList:[],
      collectList:[],
      collectScore:void 0,
      decorationList:[],
      decorationScore:void 0,
      managementList:[],
      managementScore:void 0,
      promotionList:[],
      promotionScore:void 0,
      serviceList:[],
      serviceScore:void 0,
      cleanList:[],
      cleanScore:void 0,
      storeVisualDesignList:[],
      storeVisualDesignScore:void 0,
      networkMarketingList:[],
      networkMarketingScore:void 0,
      onlineTransactionManagementList:[],
      onlineTransactionManagementScore:void 0,
      storeCustomerServiceLevelThreeList:[],
      storeCustomerServiceLevelThreeScore:void 0,
      dataAnalysisApplicationList:[],
      dataAnalysisApplicationScore:void 0,
      productServiceList: [],
      productServiceScore: void 0,
      goods_info_collect_time: void 0,
      shop_decoration_time: void 0,
      shop_management_time: void 0,
      network_promotion_time: void 0,
      store_customer_service_time: void 0,
      data_capture_clean_time: void 0,
      store_visual_design_time: void 0,
      network_marketing_time: void 0,
      online_transaction_management_time: void 0,
      store_customer_service_level_three_time: void 0,
      data_analysis_application_time: void 0,
      product_service_management_time: void 0,
    }
  },
  mounted() {
    this.getExamModuleList();
    if(this.$route.query.id){
          this.getEditData(this.$route.query.id)
        }
    
    // this.getTestQuestions();
  },
  methods:{
      //选择等级
      selectExamGrade() {
        console.log(this.paperForm.exam_grade,"ll");
        if (this.paperForm.exam_grade === 24) {
            this.paperForm.goods_info_collect = '';
            this.collectScore = void 0;
            this.goods_info_collect_time = void 0;
            this.paperForm.shop_decoration = '';
            this.decorationScore = void 0;
            this.shop_decoration_time = void 0;
            this.paperForm.shop_management = '';
            this.managementScore = void 0;
            this.shop_management_time = void 0;
            this.paperForm.network_promotion = '';
            this.promotionScore = void 0;
            this.network_promotion_time = void 0;
            this.paperForm.store_customer_service = '';
            this.serviceScore = void 0;
            this.store_customer_service_time = void 0;
            this.paperForm.data_capture_clean = '';
            this.cleanScore = void 0;
            this.data_capture_clean_time = void 0;
        }
        if (this.paperForm.exam_grade === 1) {
            this.paperForm.store_visual_design = '';
            this.storeVisualDesignScore = void 0;
            this.store_visual_design_time = void 0;
            this.paperForm.network_marketing = '';
            this.networkMarketingScore = void 0;
            this.network_marketing_time = void 0;
            this.paperForm.online_transaction_management = '';
            this.onlineTransactionManagementScore = void 0;
            this.online_transaction_management_time = void 0;
            this.paperForm.store_customer_service_level_three = '';
            this.storeCustomerServiceLevelThreeScore = void 0;
            this.store_customer_service_level_three_time = void 0;
            this.paperForm.data_analysis_application = '';
            this.dataAnalysisApplicationScore = void 0;
            this.data_analysis_application_time = void 0;
            this.paperForm.product_service_management = "";
            this.productServiceScore = void 0;
            this.product_service_management_time = void 0;
        }

        if (this.paperForm.exam_grade === 74) {
          this.paperForm.store_visual_design = '';
          this.storeVisualDesignScore = void 0;
          this.store_visual_design_time = void 0;

          this.paperForm.store_customer_service = '';
          this.serviceScore = void 0;
          this.store_customer_service_time = void 0;

          this.paperForm.data_analysis_application = '';
          this.dataAnalysisApplicationScore = void 0;
          this.data_analysis_application_time = void 0;
          this.paperForm.product_service_management = "";
          this.productServiceScore = void 0;
          this.product_service_management_time = void 0;

          this.paperForm.shop_management = '';
          this.managementScore = void 0;
          this.shop_management_time = void 0;
        }

        if (this.paperForm.exam_grade === 51){
          this.paperForm.store_visual_design = '';
          this.storeVisualDesignScore = void 0;
          this.store_visual_design_time = void 0;

          this.paperForm.goods_info_collect = '';
          this.collectScore = void 0;
          this.goods_info_collect_time = void 0;

          this.paperForm.network_promotion = '';
          this.promotionScore = void 0;
          this.network_promotion_time = void 0;

          this.paperForm.store_customer_service = '';
          this.serviceScore = void 0;
          this.store_customer_service_time = void 0;

          this.paperForm.data_capture_clean = '';
          this.cleanScore = void 0;
          this.data_capture_clean_time = void 0;

          this.paperForm.shop_management = '';
          this.managementScore = void 0;
          this.shop_management_time = void 0;
        }
        this.getTestQuestions();
      },
      //清控模块后清空分数
      clearScore(val, type) {
          if (!val && type === 1) {
              this.collectScore = void 0;
              this.goods_info_collect_time = void 0;
          }
          if (!val && type === 2) {
              this.decorationScore = void 0;
              this.shop_decoration_time = void 0;
          }
          if (!val && type === 3) {
              this.managementScore = void 0;
              this.shop_management_time = void 0;
          }
          if (!val && type === 4) {
              this.promotionScore = void 0;
              this.network_promotion_time = void 0;
          }
          if (!val && type === 5) {
              this.serviceScore = void 0;
              this.store_customer_service_time = void 0;
          }
          if (!val && type === 6) {
              this.cleanScore = void 0;
              this.data_capture_clean_time = void 0;
          }
          if (!val && type === 7) {
              this.storeVisualDesignScore = void 0;
              this.store_visual_design_time = void 0;
          }
          if (!val && type === 8) {
              this.networkMarketingScore = void 0;
              this.network_marketing_time = void 0;
          }
          if (!val && type === 9) {
              this.onlineTransactionManagementScore = void 0;
              this.online_transaction_management_time = void 0;
          }
          if (!val && type === 10) {
              this.storeCustomerServiceLevelThreeScore = void 0;
              this.store_customer_service_level_three_time = void 0;
          }
          if (!val && type === 11) {
              this.dataAnalysisApplicationScore = void 0;
              this.data_analysis_application_time = void 0;
          }
          if (!val && type === 12) {
              this.productServiceScore = void 0;
              this.product_service_management_time = void 0;
          }
      },
    //获取编辑数据
    getEditData(id){
      let params = {
        id:id
      }
      operationProblemOpEpDetail(params).then((res)=>{
        console.log('edit', res)
        this.paperForm.id = res.data.id;
        this.paperForm.name = res.data.name;
        this.paperForm.exam_grade = res.data.exam_grade;
        this.getTestQuestions();
        if (res.data.exam_grade === 1) {
            if (res.data.data_capture_clean) {
                this.paperForm.data_capture_clean = res.data.data_capture_clean.id;
                this.cleanScore = res.data.data_capture_clean.score;
                this.data_capture_clean_time = res.data.data_capture_clean.assess_time;
            }
            if (res.data.goods_info_collect) {
                this.paperForm.goods_info_collect = res.data.goods_info_collect.id;
                this.collectScore = res.data.goods_info_collect.score;
                this.goods_info_collect_time = res.data.goods_info_collect.assess_time;
            }
            if (res.data.network_promotion) {
                this.paperForm.network_promotion = res.data.network_promotion.id;
                this.promotionScore = res.data.network_promotion.score;
                this.network_promotion_time = res.data.network_promotion.assess_time;
            }
            if (res.data.shop_decoration) {
                this.paperForm.shop_decoration = res.data.shop_decoration.id;
                this.decorationScore = res.data.shop_decoration.score;
                this.shop_decoration_time = res.data.shop_decoration.assess_time;
            }
            if (res.data.shop_management) {
                this.paperForm.shop_management = res.data.shop_management.id;
                this.managementScore = res.data.shop_management.score;
                this.shop_management_time = res.data.shop_management.assess_time;
            }
            if (res.data.store_customer_service) {
                this.paperForm.store_customer_service = res.data.store_customer_service.id;
                this.serviceScore = res.data.store_customer_service.score;
                this.store_customer_service_time = res.data.store_customer_service.assess_time;
            }
        }
        if (res.data.exam_grade === 24) {
            if (res.data.store_visual_design) {
                this.paperForm.store_visual_design = res.data.store_visual_design.id;
                this.storeVisualDesignScore = res.data.store_visual_design.score;
                this.store_visual_design_time = res.data.store_visual_design.assess_time;
            }
            if (res.data.network_marketing) {
                this.paperForm.network_marketing = res.data.network_marketing.id;
                this.networkMarketingScore = res.data.network_marketing.score;
                this.network_marketing_time = res.data.network_marketing.assess_time;
            }
            if (res.data.online_transaction_management) {
                this.paperForm.online_transaction_management = res.data.online_transaction_management.id;
                this.onlineTransactionManagementScore = res.data.online_transaction_management.score;
                this.online_transaction_management_time = res.data.online_transaction_management.assess_time;
            }
            if (res.data.store_customer_service_level_three) {
                this.paperForm.store_customer_service_level_three = res.data.store_customer_service_level_three.id;
                this.storeCustomerServiceLevelThreeScore = res.data.store_customer_service_level_three.score;
                this.store_customer_service_level_three_time = res.data.store_customer_service_level_three.assess_time;
            }
            if (res.data.data_analysis_application) {
                this.paperForm.data_analysis_application = res.data.data_analysis_application.id;
                this.dataAnalysisApplicationScore = res.data.data_analysis_application.score;
                this.data_analysis_application_time = res.data.data_analysis_application.assess_time;
            }
            if (res.data.product_service_management) {
                this.paperForm.product_service_management = res.data.product_service_management.id;
                this.productServiceScore = res.data.product_service_management.score;
                this.product_service_management_time = res.data.product_service_management.assess_time;
            }
        }

        if (res.data.exam_grade === 74) {
          //三级跨境
          if (res.data.shop_management) {
            this.paperForm.shop_management = res.data.shop_management.id;
            this.managementScore = res.data.shop_management.score;
            this.shop_management_time = res.data.shop_management.assess_time;
          }

          if (res.data.store_customer_service) {
            this.paperForm.store_customer_service = res.data.store_customer_service.id;
            this.serviceScore = res.data.store_customer_service.score;
            this.store_customer_service_time = res.data.store_customer_service.assess_time;
          }
          if (res.data.network_marketing) {
                this.paperForm.network_marketing = res.data.network_marketing.id;
                this.networkMarketingScore = res.data.network_marketing.score;
                this.network_marketing_time = res.data.network_marketing.assess_time;
            }
          if (res.data.store_visual_design) {
            this.paperForm.store_visual_design = res.data.store_visual_design.id;
            this.storeVisualDesignScore = res.data.store_visual_design.score;
            this.store_visual_design_time = res.data.store_visual_design.assess_time;
          }
          if (res.data.data_analysis_application) {
            this.paperForm.data_analysis_application = res.data.data_analysis_application.id;
            this.dataAnalysisApplicationScore = res.data.data_analysis_application.score;
            this.data_analysis_application_time = res.data.data_analysis_application.assess_time;
          }
          if (res.data.product_service_management) {
            this.paperForm.product_service_management = res.data.product_service_management.id;
            this.productServiceScore = res.data.product_service_management.score;
            this.product_service_management_time = res.data.product_service_management.assess_time;
          }

        }

        if (res.data.exam_grade === 51) {
          //四级跨境
          if (res.data.shop_management) {
            this.paperForm.shop_management = res.data.shop_management.id;
            this.managementScore = res.data.shop_management.score;
            this.shop_management_time = res.data.shop_management.assess_time;
          }

          if (res.data.store_visual_design) {
            this.paperForm.store_visual_design = res.data.store_visual_design.id;
            this.storeVisualDesignScore = res.data.store_visual_design.score;
            this.store_visual_design_time = res.data.store_visual_design.assess_time;
          }
          if (res.data.store_customer_service) {
            this.paperForm.store_customer_service = res.data.store_customer_service.id;
            this.serviceScore = res.data.store_customer_service.score;
            this.store_customer_service_time = res.data.store_customer_service.assess_time;
          }

          if (res.data.network_promotion) {
            this.paperForm.network_promotion = res.data.network_promotion.id;
            this.promotionScore = res.data.network_promotion.score;
            this.network_promotion_time = res.data.network_promotion.assess_time;
          }

          if (res.data.goods_info_collect) {
            this.paperForm.goods_info_collect = res.data.goods_info_collect.id;
            this.collectScore = res.data.goods_info_collect.score;
            this.goods_info_collect_time = res.data.goods_info_collect.assess_time;
          }

          if (res.data.data_capture_clean) {
            this.paperForm.data_capture_clean = res.data.data_capture_clean.id;
            this.cleanScore = res.data.data_capture_clean.score;
            this.data_capture_clean_time = res.data.data_capture_clean.assess_time;
          }

        }
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    // 获取考试等级
    getExamModuleList(){
      operationProblemExamModuleList().then((res)=>{
        this.gradeClassList = res.data;
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    // 实操题试题列表
    getTestQuestions(){
      operationProblemEpOpList({ exam_grade: this.paperForm.exam_grade}).then((res)=>{
        this.collectList = res.data.goodsInfoCollection;
        this.decorationList = res.data.shopDecoration;
        this.managementList = res.data.shopManagement;
        this.promotionList = res.data.networkPromotion;
        this.serviceList = res.data.customerService;
        this.cleanList = res.data.dataCollectAndClean;
        // if(this.$route.query.id){
        //   this.getEditData(this.$route.query.id)
        // }
        this.storeVisualDesignList = res.data.store_visual_design;
        this.networkMarketingList = res.data.network_marketing;
        this.onlineTransactionManagementList = res.data.online_transaction_management;
        this.storeCustomerServiceLevelThreeList = res.data.store_customer_service_level_three;
        this.dataAnalysisApplicationList = res.data.data_analysis_application;
        this.productServiceList = res.data.product_service_management;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    selectChange(val,num){
      let list = [];
      switch (num){
        case 1:
          list = this.collectList;
          break;
        case 2:
          list = this.decorationList;
          break;
        case 3:
          list = this.managementList;
          break;
        case 4:
          list = this.promotionList;
          break;
        case 5:
          list = this.serviceList;
          break;
        case 6:
          list = this.cleanList;
          break;
        case 7:
          list = this.storeVisualDesignList;
          break;
        case 8:
          list = this.networkMarketingList;
          break;
        case 9:
          list = this.onlineTransactionManagementList;
          break;
        case 10:
          list = this.storeCustomerServiceLevelThreeList;
          break;
        case 11:
          list = this.dataAnalysisApplicationList;
          break;
        case 12:
          list = this.productServiceList;
      }
      list.forEach(item=>{
        if(item.id === val){
          switch (num){
            case 1:
              // this.collectScore = item.score;
              this.goods_info_collect_time = item.assess_time;
              break;
            case 2:
              // this.decorationScore = item.score;
              this.shop_decoration_time = item.assess_time;
              break;
            case 3:
              // this.managementScore = item.score;
              this.shop_management_time = item.assess_time;
              break;
            case 4:
              // this.promotionScore = item.score;
              this.network_promotion_time = item.assess_time;
              break;
            case 5:
              // this.serviceScore = item.score;
              this.store_customer_service_time = item.assess_time;
              break;
            case 6:
              // this.cleanScore = item.score;
              this.data_capture_clean_time = item.assess_time;
              break;
            case 7:
              // this.storeVisualDesignScore = item.score;
              this.store_visual_design_time = item.assess_time;
              break;
            case 8:
              // this.networkMarketingScore = item.score;
              this.network_marketing_time = item.assess_time;
              break;
            case 9:
              // this.onlineTransactionManagementScore = item.score;
              this.online_transaction_management_time = item.assess_time;
              break;
            case 10:
              // this.storeCustomerServiceLevelThreeScore = item.score;
              this.store_customer_service_level_three_time = item.assess_time;
              break;
            case 11:
              // this.dataAnalysisApplicationScore = item.score;
              this.data_analysis_application_time = item.assess_time;
              break;
            case 12: 
              this.product_service_management_time = item.assess_time;
          }
        }
      })
    },
    saveForm(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
            if (this.paperForm.exam_grade === 1) {
                if (this.paperForm.goods_info_collect ||
                    this.paperForm.shop_decoration ||
                    this.paperForm.shop_management ||
                    this.paperForm.network_promotion ||
                    this.paperForm.store_customer_service ||
                    this.paperForm.data_capture_clean) {

                } else {
                    return this.$message.warning('至少选择一个模块!')
                }

                if (this.paperForm.goods_info_collect) {
                  if (this.collectScore === void 0) {
                    return this.$message.warning('请输入商品信息采集与处理的分值')
                  }
                  if (this.goods_info_collect_time === void 0) {
                    return this.$message.warning('请输入商品信息采集与处理的考核时间')
                  }
                } else {
                  this.collectScore = void 0
                  this.goods_info_collect_time = void 0
                }
                if (this.paperForm.shop_decoration) {
                  if (this.decorationScore === void 0) {
                    return this.$message.warning('请输入网店开设的分值')
                  }
                  if (this.shop_decoration_time === void 0) {
                    return this.$message.warning('请输入网店开设的考核时间')
                  }
                } else {
                  this.decorationScore = void 0
                  this.shop_decoration_time = void 0
                }
                if (this.paperForm.shop_management) {
                  if (this.managementScore === void 0) {
                    return this.$message.warning('请输入网店管理的分值')
                  }
                  if (this.shop_management_time === void 0) {
                    return this.$message.warning('请输入网店管理的考核时间')
                  }
                } else {
                  this.managementScore = void 0
                  this.shop_management_time = void 0
                }
                if (this.paperForm.network_promotion) {
                  if (this.promotionScore === void 0) {
                    return this.$message.warning('请输入网络推广的分值')
                  }
                  if (this.network_promotion_time === void 0) {
                    return this.$message.warning('请输入网络推广的考核时间')
                  }
                } else {
                  this.promotionScore = void 0
                  this.network_promotion_time = void 0
                }
                if (this.paperForm.store_customer_service) {
                  if (this.serviceScore === void 0) {
                    return this.$message.warning('请输入网店客户服务的分值')
                  }
                  if (this.store_customer_service_time === void 0) {
                    return this.$message.warning('请输入网店客户服务的考核时间')
                  }
                } else {
                  this.serviceScore = void 0
                  this.store_customer_service_time = void 0
                }
                if (this.paperForm.data_capture_clean) {
                  if (this.cleanScore === void 0) {
                    return this.$message.warning('请输入电子商务数据收集与清洗的分值')
                  }
                  if (this.data_capture_clean_time === void 0) {
                    return this.$message.warning('请输入电子商务数据收集与清洗的考核时间')
                  }
                } else {
                  this.cleanScore = void 0
                  this.data_capture_clean_time = void 0
                }
            }
            if (this.paperForm.exam_grade === 24) {
                if (this.paperForm.store_visual_design ||
                    this.paperForm.network_marketing ||
                    this.paperForm.online_transaction_management ||
                    this.paperForm.store_customer_service_level_three
                        || this.paperForm.data_analysis_application || this.paperForm.product_service_management) {
                } else {
                    return this.$message.warning('至少选择一个模块!')
                }

                if (this.paperForm.store_visual_design) {
                  if (this.storeVisualDesignScore === void 0) {
                    return this.$message.warning('请输入网店开设的分值')
                  }
                  if (this.store_visual_design_time === void 0) {
                    return this.$message.warning('请输入网店开设的考核时间')
                  }
                } else {
                  this.storeVisualDesignScore = void 0
                  this.store_visual_design_time = void 0
                }
                if (this.paperForm.network_marketing) {
                  if (this.networkMarketingScore === void 0) {
                    return this.$message.warning('请输入网络营销的分值')
                  }
                  if (this.network_marketing_time === void 0) {
                    return this.$message.warning('请输入网络营销的考核时间')
                  }
                } else {
                  this.networkMarketingScore = void 0
                  this.network_marketing_time = void 0
                }
                if (this.paperForm.online_transaction_management) {
                  if (this.onlineTransactionManagementScore === void 0) {
                    return this.$message.warning('请输入网上交易管理的分值')
                  }
                  if (this.online_transaction_management_time === void 0) {
                    return this.$message.warning('请输入网上交易管理的考核时间')
                  }
                } else {
                  this.onlineTransactionManagementScore = void 0
                  this.online_transaction_management_time = void 0
                }
                if (this.paperForm.store_customer_service_level_three) {
                  if (this.storeCustomerServiceLevelThreeScore === void 0) {
                    return this.$message.warning('请输入网络客户服务的分值')
                  }
                  if (this.store_customer_service_level_three_time === void 0) {
                    return this.$message.warning('请输入网络客户服务的考核时间')
                  }
                } else {
                  this.storeCustomerServiceLevelThreeScore = void 0
                  this.store_customer_service_level_three_time = void 0
                }
                if (this.paperForm.data_analysis_application) {
                  if (this.dataAnalysisApplicationScore === void 0) {
                    return this.$message.warning('请输入电子商务数据与应用的分值')
                  }
                  if (this.data_analysis_application_time === void 0) {
                    return this.$message.warning('请输入电子商务数据与应用的考核时间')
                  }
                } else {
                  this.dataAnalysisApplicationScore = void 0
                  this.data_analysis_application_time = void 0
                }
                if (this.paperForm.product_service_management) {
                  if (this.productServiceScore === void 0) {
                    return this.$message.warning('请输入产品及服务信息管理的分值')
                  }
                  if (this.product_service_management_time === void 0) {
                    return this.$message.warning('请输入产品及服务信息管理的考核时间')
                  }
                } else {
                  this.productServiceScore = void 0
                  this.product_service_management_time = void 0
                }
            }
          let formData = new FormData();
          formData.append('name', this.paperForm.name);
          formData.append('exam_grade', this.paperForm.exam_grade);
          if (this.paperForm.goods_info_collect) {
              formData.append('goods_info_collect', this.paperForm.goods_info_collect);
          }
          if (this.collectScore) {
              formData.append('goods_info_collect_ratio', this.collectScore);
          }
          if (this.goods_info_collect_time) {
              formData.append('goods_info_collect_time', this.goods_info_collect_time);
          }
          if (this.paperForm.shop_decoration) {
              formData.append('shop_decoration', this.paperForm.shop_decoration);
          }
          if (this.decorationScore) {
              formData.append('shop_decoration_ratio', this.decorationScore);
          }
          if (this.shop_decoration_time) {
              formData.append('shop_decoration_time', this.shop_decoration_time);
          }
          if (this.paperForm.shop_management) {
              formData.append('shop_management', this.paperForm.shop_management);
          }
          if (this.managementScore) {
              formData.append('shop_management_ratio', this.managementScore);
          }
          if (this.shop_management_time) {
              formData.append('shop_management_time', this.shop_management_time);
          }
          if (this.paperForm.network_promotion) {
              formData.append('network_promotion', this.paperForm.network_promotion);
          }
          if (this.promotionScore) {
              formData.append('network_promotion_ratio', this.promotionScore);
          }
          if (this.network_promotion_time) {
              formData.append('network_promotion_time', this.network_promotion_time);
          }
          if (this.paperForm.store_customer_service) {
              formData.append('store_customer_service', this.paperForm.store_customer_service);
          }
          if (this.serviceScore) {
              formData.append('store_customer_service_ratio', this.serviceScore);
          }
          if (this.store_customer_service_time) {
              formData.append('store_customer_service_time', this.store_customer_service_time);
          }
          if (this.paperForm.data_capture_clean) {
              formData.append('data_capture_clean', this.paperForm.data_capture_clean);
          }
          if (this.cleanScore) {
              formData.append('data_capture_clean_ratio', this.cleanScore);
          }
          if (this.data_capture_clean_time) {
              formData.append('data_capture_clean_time', this.data_capture_clean_time);
          }
          if (this.paperForm.store_visual_design) {
              formData.append('store_visual_design', this.paperForm.store_visual_design);
          }
          if (this.storeVisualDesignScore) {
              formData.append('store_visual_design_ratio', this.storeVisualDesignScore);
          }
          if (this.store_visual_design_time) {
              formData.append('store_visual_design_time', this.store_visual_design_time);
          }
          if (this.paperForm.network_marketing) {
              formData.append('network_marketing', this.paperForm.network_marketing);
          }
          if (this.networkMarketingScore) {
              formData.append('network_marketing_ratio', this.networkMarketingScore);
          }
          if (this.network_marketing_time) {
              formData.append('network_marketing_time', this.network_marketing_time);
          }
          if (this.paperForm.online_transaction_management) {
              formData.append('online_transaction_management', this.paperForm.online_transaction_management);
          }
          if (this.onlineTransactionManagementScore) {
              formData.append('online_transaction_management_ratio', this.onlineTransactionManagementScore);
          }
          if (this.online_transaction_management_time) {
              formData.append('online_transaction_management_time', this.online_transaction_management_time);
          }
          if (this.paperForm.store_customer_service_level_three) {
              formData.append('store_customer_service_level_three', this.paperForm.store_customer_service_level_three);
          }
          if (this.storeCustomerServiceLevelThreeScore) {
              formData.append('store_customer_service_level_three_ratio', this.storeCustomerServiceLevelThreeScore);
          }
          if (this.store_customer_service_level_three_time) {
              formData.append('store_customer_service_level_three_time', this.store_customer_service_level_three_time);
          }
          if (this.paperForm.data_analysis_application) {
              formData.append('data_analysis_application', this.paperForm.data_analysis_application);
          }
          if (this.dataAnalysisApplicationScore) {
              formData.append('data_analysis_application_ratio', this.dataAnalysisApplicationScore);
          }
          if (this.data_analysis_application_time) {
              formData.append('data_analysis_application_time', this.data_analysis_application_time);
          }
          if (this.paperForm.product_service_management) {
            formData.append('product_service_management', this.paperForm.product_service_management);
          }
          if (this.productServiceScore) { 
            formData.append('product_service_management_ratio', this.productServiceScore);
          }
          if (this.product_service_management_time) {
              formData.append('product_service_management_time', this.product_service_management_time);
          }
          if(this.paperForm.id){
            formData.append('id', this.paperForm.id)
          }
          operationProblemSaveOpExamPapers(formData).then((res)=>{
            this.$message.success(res.msg);
            this.$router.go(-1)
          }).catch((err)=>{
            console.log('err', err)
          })
        } else {
          return false
        }
      })
    },
    toBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.main-wrapper{
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 0px 20px;
    }
  }
  .dialog-footer{
    text-align: center;
    margin: 150px 0 50px;
  }
}
</style>